<template>
  <div
    class="relative h-full overflow-hidden flex flex-col"
    style="background-color: #525659"
  >
    <div
      class="h-[52px] px-14 w-full shrink-0"
      style="background-color: #323639"
    >
      <div
        class="h-full flex items-center justify-between"
        style="color: #ffffff"
      >
        <div class="font-bold no-select">{{ fileName }}</div>

        <div class="flex gap-8">
          <div
            class="rounded-full hover:bg-gray-600 h-10 w-10 flex items-center justify-around"
          >
            <hi-icon
              :path="mdiMagnifyMinus"
              @click="onMagnify(false)"
              size="20px"
            />
          </div>
          <div
            class="rounded-full hover:bg-gray-600 h-10 w-10 flex items-center justify-around"
          >
            <hi-icon
              :path="mdiMagnifyPlus"
              @click="onMagnify(true)"
              size="20px"
            />
          </div>
          <div
            class="rounded-full hover:bg-gray-600 h-10 w-10 flex items-center justify-around"
          >
            <hi-icon :path="mdiOverscan" @click="onFullHeight" size="20px" />
          </div>
          <div
            class="rounded-full hover:bg-gray-600 h-10 w-10 flex items-center justify-around"
          >
            <hi-icon :path="mdiFullscreen" @click="onFullWidth" size="20px" />
          </div>
        </div>
        <div class="flex gap-4">
          <div
            class="rounded-full hover:bg-gray-600 h-10 w-10 flex items-center justify-around"
          >
            <hi-icon :path="mdiDownload" size="20px" @click="download" />
          </div>
          <div
            class="rounded-full hover:bg-gray-600 h-10 w-10 flex items-center justify-around"
          >
            <hi-icon :path="mdiPrinterOutline" @click="onPrint" size="20px" />
          </div>
        </div>
      </div>
    </div>
    <div class="overflow-auto">
      <hi-pdf-viewer
        class="w-full"
        :width="pdfLayout.pdfWidth"
        :height="pdfLayout.pdfHeight"
        v-if="source"
        :source="source"
      />
    </div>
  </div>
</template>

<script>
import HiPdfViewer from "@/components/HiPdfViewer";
import { onMounted, reactive, ref } from "vue";
import {
  mdiMagnifyPlus,
  mdiMagnifyMinus,
  mdiFullscreen,
  mdiOverscan,
  mdiDownload,
  mdiPrinterOutline,
} from "@mdi/js";
import HiIcon from "@/hive-vue3/components/HiIcon";
import { downloadFileFromUrl } from "@/hive-vue3/utils/fileSaver";
import { useRoute, useRouter } from "vue-router";
import { AES, enc } from "crypto-js";
import printJS from "print-js";
import {
  requestQuotationFile,
  requestQuotationFileNoBackground,
} from "@/requests/requests";

export default {
  name: "QuotationViewer",
  components: {
    HiIcon,
    HiPdfViewer,
  },
  setup() {
    const source = ref(undefined);

    const fileName = ref(undefined);
    const route = useRoute();
    const router = useRouter();

    const pdfLayout = reactive({
      pdfWidth: 800,
      pdfHeight: 600,
      outerWidth: 800,
    });

    onMounted(async () => {
      pdfLayout.pdfWidth = Math.round(window.innerWidth * 0.7);
      pdfLayout.outerWidth = pdfLayout.pdfWidth;
      // console.log("email", route.query);
      try {
        const email = AES.decrypt(
          route.query.contact.replace(/\s/g, "+"),
          "hive.properties"
        ).toString(enc.Utf8);
        if (email) {
          const fileData = await requestQuotationFile(
            route.params.activityId,
            email
          );
          source.value = fileData.data.url;
          fileName.value = fileData.data.name;
          // console.log(source.value, fileName.value);
        } else {
          await router.push("/404");
        }
      } catch (e) {
        console.error(e);
        await router.push("/404");
      }
    });

    async function download() {
      try {
        const email = AES.decrypt(
          route.query.contact.replace(/\s/g, "+"),
          "hive.properties"
        ).toString(enc.Utf8);

        if (email) {
          const fileData = await requestQuotationFileNoBackground(
            route.params.activityId,
            email
          );

          downloadFileFromUrl(
            fileData.data.url,
            fileData.data.name,
            "application/pdf"
          );
        } else {
          await router.push("/404");
        }
      } catch (e) {
        console.error(e);
        await router.push("/404");
      }
    }

    function onMagnify(increase) {
      if (!pdfLayout.pdfWidth) {
        pdfLayout.pdfWidth = pdfLayout.outerWidth;
      }

      if (increase) {
        pdfLayout.pdfWidth = pdfLayout.pdfWidth + 150;
      } else {
        pdfLayout.pdfWidth = pdfLayout.pdfWidth - 150;
      }
      pdfLayout.outerWidth = pdfLayout.pdfWidth;
    }

    function onFullWidth() {
      pdfLayout.pdfWidth = Math.round(window.innerWidth - 25);
      pdfLayout.outerWidth = pdfLayout.pdfWidth;
    }
    function onFullHeight() {
      pdfLayout.pdfWidth = undefined;
      pdfLayout.pdfHeight = Math.round(window.innerHeight - 52);
      pdfLayout.outerWidth = Math.round(pdfLayout.pdfHeight / 1.414);
      // console.log(pdfLayout);
    }

    async function onPrint() {
      const email = AES.decrypt(
        route.query.contact.replace(/\s/g, "+"),
        "hive.properties"
      ).toString(enc.Utf8);

      const fileData = await requestQuotationFileNoBackground(
        route.params.activityId,
        email
      );

      printJS({
        printable: fileData.data.url,
        type: "pdf",
        properties: ["prop1", "prop2", "prop3"],
      });
    }

    return {
      source,
      mdiMagnifyPlus,
      mdiMagnifyMinus,
      mdiFullscreen,
      mdiOverscan,
      mdiPrinterOutline,
      mdiDownload,
      download,
      fileName,
      onMagnify,
      onFullHeight,
      pdfLayout,
      onFullWidth,
      onPrint,
    };
  },
};
</script>

<style scoped></style>
