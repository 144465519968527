<template>
  <div class="flex flex-col space-y-2 hi-form">
    <div v-if="debug" class="flex gap-2">
      <div class="debug-label">changed: {{ changed }}</div>
      <div class="debug-label">holding: {{ isHolding }}</div>
      <div class="debug-label">valid: {{ valid }}</div>
    </div>
    <slot />
    <div class="flex flex-row-reverse pt-4">
      <hi-button
        primary
        @click="submit"
        class="cursor-pointer"
        :class="{
          disabled:
            disabled ||
            !valid ||
            (submitWithoutChange ? false : !changed) ||
            isHolding,
        }"
        v-if="submitButton"
      >
        {{ submitText }}
      </hi-button>
      <slot name="tools-right" />
      <div class="flex-grow"></div>
      <slot name="tools-left" />

      <hi-button v-if="debug" @click="logValues"> Log Values </hi-button>
      <hi-button @click="cancel" v-if="cancelButton">
        {{ cancelText }}
      </hi-button>
    </div>
    <pre v-if="debug"> {{ data }}</pre>
  </div>
</template>

<script>
import formData from "./logics/formData";
import { provide } from "vue";
import HiButton from "../HiButton";
import injectionKeys from "@/hive-vue3/components/_commonLogic/injectionKeys";
import { watchPropsImmediately } from "@/hive-vue3/utils/reactiveHelpers/watchers";

export default {
  name: "HiForm",
  components: { HiButton },
  props: {
    submitButton: {
      type: Boolean,
      default: true,
    },
    submitText: {
      type: String,
      default: "Submit",
    },
    cancelButton: Boolean,
    cancelText: {
      type: String,
      default: "Cancel",
    },
    disabled: Boolean,
    debug: Boolean,
    submitWithoutChange: Boolean,
    // modelValue: Object,
    /**
     * this is none reactive. Read once when loaded.
     */
    initialValues: Object,
  },
  emits: ["submit", "cancel", "changed"],
  setup(props, { emit }) {
    const data = formData(props.initialValues, props.debug);
    // data.onUpdate((values) => {
    //   // console.log(values);
    //   emit("update:formData", values);
    // });
    provide(injectionKeys.HI_FORM_DATA, data);
    function submit() {
      emit("submit", data.values);
      if (props.debug) console.log(data.values);
    }
    function logValues() {
      console.log(data.values);
    }
    function cancel() {
      data.cancel();
      emit("cancel");
    }

    watchPropsImmediately(data.changed, "value", (v) => {
      emit("changed", v);
    });

    return {
      submit,
      valid: data.valid,
      cancel,
      changed: data.changed,
      isHolding: data.isHolding,
      logValues,
      data: data.values,
    };
  },
};
</script>

<style scoped></style>
