import { createRouter, createWebHistory } from "vue-router";
import quotationViewer from "@/views/QuotationViewer";
import appState from "@/appState";
import notFound from "@/views/notFound";
import unsubscribeView from "@/views/UnsubscribeView";

const routes = [
  {
    path: "/",
    name: "home",
    component: notFound,
  },
  {
    path: "/404",
    name: "notFound",
    component: notFound,
  },
  {
    path: "/quote/:activityId",
    name: "quote",
    component: quotationViewer,
  },
  {
    path: "/unsubscribe/:peopleId",
    name: "unsubscribe",
    component: unsubscribeView,
    props: (route) => {
      return { peopleId: route.params.peopleId };
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach(() => {
  // console.log("beforeEach");
  appState.isHeroFullscreen = false;
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
});
export default router;
