import axios from "axios";
import { signatureGenerator } from "@/hive-vue3/api/tokenGenerate";
import { serverAddress } from "@/conf/serverAddress";

export const requestQuotationFile = async (activityId, email) => {
  return await requestPost("/website/quotation", {
    activityId,
    email,
  });
};
export const requestQuotationFileNoBackground = async (activityId, email) => {
  return await requestPost("/website/quotation/noBackground", {
    activityId,
    email,
  });
};

export const requestUnsubscribeMailingLists = async (
  mailingLists,
  peopleId
) => {
  return await requestPost("/website/mailingList/unsubscribe", {
    mailingLists,
    peopleId,
  });
};

const requestPost = async (url, data) => {
  const signature = signatureGenerator();
  const res = await axios.post(serverAddress + url, { ...data, signature }, {});
  return res.data;
};
