import CryptoJS from "crypto-js";

const rand = () => Math.random(0).toString(36).substr(2);
const tokenGenerator = (length) => {
  return (rand() + rand() + rand() + rand()).substr(0, length);
};

export const signatureGenerator = () => {
  const time = new Date().getTime();
  const timestamp = Math.floor(time / 1000);
  const token = tokenGenerator();
  const signature = CryptoJS.HmacSHA256(
    Math.floor(time / 1000) + token,
    "key-aGl2ZS5wcm9wZXJ0aWVz"
  ).toString();
  return {
    timestamp,
    token,
    signature,
  };
};
