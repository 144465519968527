<template>
  <router-view class="main-view min-h-full" v-slot="{ Component }">
    <transition-fade>
      <component :is="Component" />
    </transition-fade>
  </router-view>
</template>

<style lang="scss">
@use "app";
@use "hive-vue3/styles/full-size-app.css";
</style>
<script>
import TransitionFade from "@/hive-vue3/transitions/TransitionFade";
import "@/forms/styles/hiveForm-bnw-outline.scss";

export default {
  components: { TransitionFade },
};
</script>
