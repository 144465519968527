import { reactive, computed, toRef, watchEffect } from "vue";
import {
  GoogleAuthProvider,
  setPersistence,
  getAuth,
  browserLocalPersistence,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import {
  firestoreDoc,
  getFirestoreDocData,
  updateFirestoreDoc,
} from "@/hive-vue3/firebase/utils";
import { requestCreateUser } from "@/hive-vue3/api/request";
import { serverTimestamp } from "firebase/firestore";
import useFirestoreDocReactiveId from "@/hive-vue3/firebase/useFirestoreDocReactiveId";
import useFirestoreDoc from "@/hive-vue3/firebase/useFirestoreDoc";
import { watchForever } from "@/hive-vue3/utils/reactiveHelpers/watchers";
import { deleteAllItemsInObject } from "@/hive-vue3/utils/objectUtils";
import useFirestoreCollectionAsRef from "@/hive-vue3/firebase/useFirestoreCollectionAsRef";

let entryPath;
let loginViewName = "login";
let mainRouter;

const usersDbPath = "/system/users/people/";
// const userPermissionsDocPath = "/system/userPermissions";

export async function googleLoginPopup() {
  const provider = new GoogleAuthProvider();
  const auth = getAuth();
  await setPersistence(auth, browserLocalPersistence);
  signInWithPopup(auth, provider)
    .then(async (result) => {
      if (result) {
        console.log("result=", result);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

/**
 *
 * @returns {Promise<void>}
 */
export async function logout() {
  state.currentUser = undefined;
  state.currentUserId = undefined;
  // console.log("logout");
  const auth = getAuth();
  // console.log(auth);
  await signOut(auth);
}

const state = reactive({
  authLoaded: false,
  currentUser: undefined,
  currentUserId: undefined,
  loginError: undefined,
});

/**
 * Call this function in main.js to setup Firebase Auth.
 * @param router
 * @param loginView {String} router name for login view.

 * @param history {Number}
 */
export function setupAuth(
  router,
  {
    loginView = "login",
    // usersDbPath = "/system/users/people",
    history = 50,
  }
) {
  const auth = getAuth();

  loginViewName = loginView;
  mainRouter = router;
  router.beforeEach((to, from, next) => {
    // console.log("topath", to.path);
    if (!entryPath) entryPath = to.path;

    const requiresAuth = to.matched.some((record) => record.meta.auth);
    if (!isLoggedIn() && requiresAuth) {
      next(loginView);
    } else {
      next();
    }
  });

  onAuthStateChanged(auth, async (user) => {
    state.authLoaded = true;
    if (user && user.uid) {
      state.authLoaded = false;
      // "/system/users/people"
      const doc = firestoreDoc(usersDbPath, user.uid);
      let docData = await getFirestoreDocData(doc);

      // const claims = await requestCheckUser();
      // const claims = await user.getIdTokenResult();
      // console.log(claims);
      // console.log(user);
      if (!docData) {
        // 如果没有该用户
        const result = await requestCreateUser();
        docData = await getFirestoreDocData(doc);
        if (!result["successful"]) {
          state.loginError = "Login Error: UnAuthrized User";
          await logout();
          mainRouter.push(loginViewName);
          return;
        }
      }

      const ts = serverTimestamp();
      const data = { lastLoginAt: ts };
      if (history) {
        const lastLoginAt = docData.lastLoginAt;
        if (lastLoginAt) {
          const loginHistory = docData.loginHistory || [];
          loginHistory.unshift(lastLoginAt);
          if (loginHistory.length > history) {
            loginHistory.pop();
          }
          data.loginHistory = loginHistory;
        }
      }
      await updateFirestoreDoc(doc, data, { meta: false });

      /**
       * getUsers()
       */
      getUsers();
      /////////////////////////////////
      // console.log(formControls.uid);
      state.authLoaded = true;
      if (user.email.endsWith(".com.au")) {
        user.email = user.email.replace(".com.au", ".au");
      }
      state.currentUser = user;
      state.currentUserId = user.uid;
      // state.appUser = useUser(formControls.uid);
      // console.log(entryPath);
      const entryPathName = entryPath.substr(1);
      // console.log(entryPathName);
      if (entryPathName === loginViewName) entryPath = "/";
      await router.replace(entryPath);
    } else {
      mainRouter.push(loginViewName);
    }
  });
}

/**
 * Reactive value for isLoggedIn().
 *
 */
export const useLoggedIn = () =>
  computed(() => state.authLoaded && !!state.currentUser);
/**
 * Reactive value for 'is auth loaded'
 *
 */
export const useAuthLoaded = () => computed(() => state.authLoaded);

const cache = {};
/**
 * Reactive Object for Firebase User Object
 *
 */
export const useCurrentFirebaseUser = () => {
  if (!state.currentUserId) return {};
  if (!cache.currentUser)
    cache.currentUser = useFirestoreDocReactiveId(
      usersDbPath,
      state,
      "currentUserId",
      {
        persistData: true,
      }
    );
  // console.log(cache.currentUser);
  return cache.currentUser;
};
// export const useCurrentFirebaseUser = () => computed(() => state.currentUser);

export const useCurrentFirebaseUserPermissions = () => {
  if (!cache.permissions) {
    cache.permissions = useFirestoreDoc("system", "userPermissions", {
      persistData: true,
    });
    cache.currentUserPermissions = reactive({});
    // console.log(state.currentUserId);
    watchEffect(() => {
      const value = cache.permissions;
      deleteAllItemsInObject(cache.currentUserPermissions);

      if (state.currentUserId) {
        const perm = value[state.currentUserId];
        if (!perm) return;
        const roles = perm.roles;
        if (roles) {
          for (let role in roles) {
            if (role) cache.currentUserPermissions[roles[role]] = true;
          }
        }
      }
    });
  }
  return cache.currentUserPermissions;
};

/**
 * Reactive value for current user id
 *
 */
export const useLoginError = () => computed(() => state.loginError);
export const useCurrentFirebaseUserId = () =>
  computed(() => state.currentUserId);
/**
 * Get current user id
 * @returns {String}
 */
export const getCurrentFirebaseUserId = () => state.currentUserId;

export const getCurrentFirebaseUser = () => state.currentUser;

/**
 * Is user logged in.
 * @returns {boolean}
 */
export function isLoggedIn() {
  return !!state.currentUser;
}

const systemUsers = reactive({});
const systemRoles = reactive({});
let userCollection;
let permissionsDoc;
function getUsers() {
  if (userCollection) return;
  userCollection = useFirestoreCollectionAsRef(usersDbPath, {
    persistData: true,
  });
  permissionsDoc = useFirestoreDoc("system", "userPermissions", {
    persistData: true,
  });
  watchForever(userCollection, (users) => {
    deleteAllItemsInObject(systemUsers);
    // console.log(users);
    for (let user of users) {
      const id = user.id;
      systemUsers[id] = user;
      // console.log(id, user);
      if (permissionsDoc[id]) {
        systemUsers[id].roles = permissionsDoc[id].roles;
      }
    }
    // console.log(systemUsers);
  });
  watchForever(permissionsDoc, () => {
    for (let id in permissionsDoc) {
      if (systemUsers[id]) {
        systemUsers[id].roles = permissionsDoc[id].roles;
      }
    }
    if (permissionsDoc.roles) {
      deleteAllItemsInObject(systemRoles);
      Object.assign(systemRoles, permissionsDoc.roles);
    }
  });
}
export const useSystemUsers = () => {
  getUsers();
  return systemUsers;
};
export const useSystemRoles = () => {
  getUsers();
  return systemRoles;
};

export function useSystemUser(userId) {
  return toRef(systemUsers, userId);
}

export function useSystemUserReactiveId(reactiveObj, idKey = "userId") {
  return computed(() => {
    const id = reactiveObj[idKey];
    return systemUsers[id];
  });
}
