<template>
  <div class="flex justify-center">
    <vue-pdf-embed
      :height="height"
      :width="width"
      :source="source"
      :page="page"
    />
  </div>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed";

export default {
  name: "HiPdfViewer",
  components: {
    VuePdfEmbed,
  },
  props: {
    source: String,
    page: Number,
    width: Number,
    height: Number,
  },
};
</script>

<style scoped>
#pageContainer {
  margin: auto;
  width: 80%;
}

div.page {
  display: inline-block;
}

div /deep/ canvas {
  transition: all 0.5s ease-out;
}
</style>
