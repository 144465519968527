<template>
  <div class="max-w-[1000px] mx-auto mt-3vh">
    [Artimber] Unsubscribe
    <hi-form-hive-standard v-if="!status.submitted" :submit-fn="submit">
      <!--      <hi-check form-key="ads"> Ads</hi-check>-->
      <!--      <hi-check form-key="projectUpdates"> Project Updates</hi-check>-->
      <!--      <hi-check form-key="festivals">Festivals</hi-check>-->
      <hi-check form-key="all"> No further EDM messages.</hi-check>
    </hi-form-hive-standard>
    <div v-else>Unsubscribe Successful</div>
  </div>
</template>

<script>
import HiFormHiveStandard from "@/hive-vue3/components/form/HiFormHiveStandard";
import HiCheck from "@/hive-vue3/components/form/controls/HiCheck";
import { reactive } from "vue";
import { requestUnsubscribeMailingLists } from "@/requests/requests";
export default {
  name: "UnsubscribeView",
  components: { HiCheck, HiFormHiveStandard },
  props: {
    peopleId: String,
  },
  setup(props) {
    const status = reactive({
      submitted: false,
    });

    async function submit(data) {
      const mailingList = [];
      console.log(window.location.host, data);

      for (const i of Object.keys(data)) {
        if (data[i]) {
          mailingList.push(i);
        }
      }

      await requestUnsubscribeMailingLists(mailingList, props.peopleId);
      status.submitted = true;
      return {
        successful: true,
      };
    }
    return {
      submit,
      status,
    };
  },
};
</script>

<style scoped></style>
