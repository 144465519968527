<template>
  <div class="w-full h-full">
    <center-box>
      <div class="text-2xl">404 Not Found.</div>
      <br />
      <div><a href="https://artimber.com.au">artimber.com.au</a></div>
    </center-box>
  </div>
</template>

<script>
import CenterBox from "@/hive-vue3/layouts/CenterBox";
export default {
  name: "notFound",
  components: { CenterBox },
};
</script>

<style scoped></style>
